<template>
<v-card class="m-0 p-0 bg-background">
  <div class="background">
  <v-layout class="m-0 p-0 bg-background" style="overflow-y: scroll; height: 100%">
    <v-app-bar
      color="primary"
      prominent
      extended
      :extension-height="(!drawer && !!qu.id) ? 60 : 0"
    >
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
        <v-badge v-if="queuesList.length > 1" :content="queuesList.length" color="red-darken-3">
          <img src="./assets/menu.svg" style="filter: invert(50);" class="mx-2 w-100"/>
        </v-badge>
        <img v-else src="./assets/menu.svg" style="filter: invert(50);" class="mx-2 w-100"/>
      </v-app-bar-nav-icon>
      <v-toolbar-title id="qu-title" class="text-end me-4">{{$t('message.main.eQueue').toUpperCase()}}</v-toolbar-title>
      <template v-if="!!qu.id" v-slot:extension>
        <div class="h-100 w-100 bg-background">
          <v-container style="max-width: 600px" class="py-0">
            <v-row class="m-0 p-0">
              <v-col cols="2" class="text-start py-4">
                <img src="./assets/qlogo.png" height="52px"/>
              </v-col>
              <v-col cols="10" class="text-end">
                <h2 class="mt-3 text-warning">
                  {{qu.title}}
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      location="start"
      color="background"
      temporary
    >
      <v-list-item class="text-start mt-4" @click.stop="landingUrl">
        {{$t('message.navbar.about')}}
      </v-list-item>
      <v-divider></v-divider>    
        <template v-if="queuesList.length > 1">
        <v-list-item-subtitle class="text-start pa-4">
          {{$t('message.navbar.queues')}}
        </v-list-item-subtitle>      
        <v-list-item 
          v-for="(item, key) in queuesList" 
          :key="key" 
          @click.stop="queueItemSelect(item.token)" 
          :class="'text-start text-primary' + ((item.token == qu.token) ? ' bg-background_y': ' bg-white')"
        >
          {{item.title}}
        </v-list-item> 
        <v-divider></v-divider>
      </template>
      <v-list-item-subtitle class="text-start pa-4">
        {{$t('message.navbar.lang')}}
      </v-list-item-subtitle>
      <template v-for="(item, key) in langs" :key="key">
        <v-list-item 
          v-if="item.valid" 
          @click.stop="langSelect(item.name)" 
          :class="'text-start' + ((item.name == config.locale) ? ' bg-background_y' : ' bg-white')"
        > 
          {{item.title}}
        </v-list-item>
      </template>
      <template v-if="def.log">
        <v-divider></v-divider>
        <v-list-item class="text-start pa-4" @click.stop="wipeConfirmDialog">
          {{$t('message.main.wipe')}}
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item-subtitle class="text-start pa-4">
        QueueStop 1.3.2<br/>
        © LaFuente 2024<br/>
        Build 241030
        <span v-if="def.log"> • {{fbTokenId || ''}}</span>
      </v-list-item-subtitle>      
    </v-navigation-drawer>
    <v-main class="pb-8">
      <v-container style="max-width: 600px">
        <template v-if="!qu.id">
          <v-fade-transition>
            <v-row v-show="welcome == 2" justify="center">
              <v-col cols="12">
                <img src="./assets/scan.png" class="mt-4 w-100"/>
              </v-col>
            </v-row>
          </v-fade-transition>
          <v-fade-transition>
            <v-row v-show="welcome == 2" justify="center">
              <v-col cols="12" class="mt-n12 bg-blue-lighten-4 rounded-lg">
                <h3 class="text-primary">
                  {{$t('message.main.scanQr')}}
                </h3>
              </v-col>
            </v-row>
          </v-fade-transition>
        </template>
        <template v-if="qu.id" >
          <v-row justify="center">
            <v-col cols="12" class="my-0 py-0">&nbsp;</v-col>
          </v-row>
          <template v-if="qu.config?.show?.shedule" >
            <v-row v-if="qu.shedule?.workday" justify="center">
              <v-col cols="12" class="my-0 py-0">
                <h3 class="text-primary">{{$t('message.main.worktime')}}</h3>
                <h3 class="text-warning">{{qu.shedule?.workday?.from}} – {{qu.shedule?.workday?.to}}</h3>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" class="my-0 py-0">
                <h3 class="text-primary">{{$t('message.main.timebreak', qu.shedule?.breaks?.length)}}</h3>
                <template v-if="qu.shedule?.breaks?.length">
                  <h3 v-for="(brk, idx) in qu.shedule?.breaks" :key="idx" class="text-warning">
                    {{brk.from}} – {{brk.to}}
                  </h3>
                </template>
              </v-col>
            </v-row>       
            <v-row v-if="qu.shedule?.booktime?.length" justify="center">
              <v-col cols="12" class="my-0 py-0">
                <h3 class="text-primary">{{$t('message.main.booktime')}}</h3>
                <h3 v-for="(brk, idx) in qu.shedule.booktime" :key="idx" class="text-warning">
                  {{brk.from}} – {{brk.to}}
                </h3>
              </v-col>
            </v-row>   
            <v-divider class="my-4"></v-divider>
          </template>
          <v-row v-if="qu.point?.token" justify="center">
            <v-col class="qu-stat" cols="12">
              <template v-if="qu.config?.show?.idx">
                <h3 class="my-0 text-primary">{{$t('message.main.yourPoint')}}</h3>
                <h1 class="my-0 text-warning">{{qu.point?.numero}}</h1>
              </template>
              <template v-if="qu.statistic && !qu.statistic?.in">
                <h1 v-if="qu.statement == statement.free" class="my-0 text-warning">{{$t('message.main.nowQueue')}}</h1>
                <h1 v-else class="my-0 text-warning">{{$t('message.main.youNext')}}</h1>
                <p v-if="qu.statement == statement.service" class="qu-memo">{{$t('message.main.nowQueueInfo')}}</p>
              </template>
            </v-col>
          </v-row>
          <v-divider v-if="qu.point?.token && (qu.config?.show?.idx || (qu.statistic && !qu.statistic?.in))" class="my-4"></v-divider>          
          <template v-if="qu.statistic && (!qu.point?.token || qu.statistic?.in || (qu.statement != statement.free))">
            <v-row justify="center" v-if="qu.config?.show?.before || !qu.point?.token">
              <v-col class="qu-stat" cols="12" >
                <h3 class="qu-stat text-primary" >{{$t('message.main.beforeYour', qu.statistic?.in)}}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="qu.config?.show?.after || !qu.point?.token">
              <v-col class="qu-stat" cols="12" >
                <h3 class="qu-stat text-primary" >{{$t('message.main.afterYour', afterYou)}}</h3>
              </v-col>          
            </v-row>        
            <v-row v-if="qu.config?.show?.average && averageService" justify="center">
              <v-col class="qu-stat" cols="12" >
                <h3 class="qu-stat text-primary" >{{$t('message.main.averageService')}}</h3>
                <h3 class="text-warning">{{averageService?.toLocaleTimeString('uk',{timeZone: "UTC"})}}</h3>
                <p class="qu-memo" v-html="$t('message.main.statInfo')"></p>
              </v-col>      
            </v-row>
            <v-divider class="my-4" v-if="!qu.point?.token || qu.config?.show?.after || qu.config?.show?.before"></v-divider>   
          </template>
          <template v-if="!firebaseLocker">
            <v-row v-if="qu.point?.token && userdataUrl && (qu.config?.userdata?.skip < qu.statistic?.in)" justify="center">
              <v-col cols="12" >
                <p class="qu-memo" v-html="$t('message.main.userdataInfo')"></p>
                <v-btn color="success" class="w-100" elevation="6" :href="userdataUrl" target="_blank">
                  {{$t('message.main.userdata')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="qu.point?.token && userinfoUrl && (qu.config?.userinfo?.skip < qu.statistic?.in)" justify="center">
              <v-col cols="12" >
                <p class="qu-memo" v-html="$t('message.main.userInfoInfo')"></p>
                <v-btn color="success" class="w-100" elevation="6" :href="userinfoUrl" target="_blank">
                  {{$t('message.main.userInfo')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="qu.point?.token && userpayUrl && (qu.config?.userpay?.skip < qu.statistic?.in)" justify="center">
              <v-col cols="12" >
                <p class="qu-memo" v-html="$t('message.main.userPayInfo')"></p>
                <v-btn color="success" class="w-100" elevation="6" :href="userpayUrl" target="_blank">
                  {{$t('message.main.userPay')}}
                </v-btn>
              </v-col>
            </v-row>            
            <template v-if="!qu.point?.token">
              <template v-if="(qu.statement == statement.closed) || (timestamp && !booktimeState)">
                <v-row justify="center">
                  <v-col cols="12" >
                    <h4 class="qu-stat mb-2" >{{$t('message.dialogs.impossibleIn')}}</h4>
                    <v-btn color="primary" class="w-100" elevation="6" @click.stop="killCurrentQueue(true)">{{$t('message.main.confirm')}}</v-btn>
                  </v-col>        
                </v-row>                
              </template>
              <template v-else>
                <v-row justify="center">
                  <v-col cols="12" >
                    <v-btn color="success" class="w-100" elevation="6" @click.stop="connect">{{$t('message.main.connectQueue')}}</v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" >
                    <v-btn color="primary" class="w-100" elevation="6" @click.stop="killCurrentQueue(true)">{{$t('message.main.cancel')}}</v-btn>
                  </v-col>        
                </v-row>
              </template>
            </template>
            <v-row v-if="qu.point?.token && qu.config?.show?.selfcomplete && !qu.statistic?.in && (qu.statement == statement.free)" justify="center"> 
              <v-col cols="12" >             
                <v-btn color="success" class="w-100" elevation="6" @click.stop="complete">{{$t('message.main.completeQueue')}}</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="qu.point?.token && afterYou && qu.config?.allow_skip" justify="center">
              <v-col cols="12">
                <p class="qu-memo" v-html="$t('message.main.skipQueueInfo')"></p>
                <v-btn color="primary" class="w-100" elevation="6" @click.stop="skip">{{$t('message.main.skipQueue')}}</v-btn>
              </v-col>
            </v-row>      
            <v-row v-if="qu.point?.token" justify="center">
              <v-col cols="12">
                <p class="qu-memo" v-html="$t('message.main.dropQueueInfo')"></p>
                <v-btn color="warning" class="w-100" elevation="6" @click.stop="dropConfirmDialog">{{$t('message.main.dropQueue')}}</v-btn>
              </v-col>
            </v-row>     
          </template>
          <v-row class="my-4">
          </v-row>
        </template> 
      </v-container>
    </v-main>
  </v-layout>
  </div>   
</v-card>
    <v-overlay 
      v-model="busy" 
      persistent
      contained
      class="align-center justify-center"
      :opacity="0.75"
      color="black"      
    >  
      <div class="text-center">
            <v-progress-circular
            indeterminate
            style="opacity: 0.6"
            :size="300"
            :width="60"
            color="white"         
            ></v-progress-circular> 
      </div>
    </v-overlay> 
  <v-dialog v-model="dialogs.show" persistent width="500">
    <v-card :title="dialogs.title" color="background">
      <v-card-text v-html="dialogs.theme" class="text-start"/>
      <v-card-text v-if="dialogs.textarea">
        <v-textarea 
          :label="dialogs.textarea"
          no-resize
          autofocus
          hide-details
          rows="2"
          variant="solo"
          v-model="dialogs.data.text"
        ></v-textarea>
      </v-card-text>
      <v-card-actions v-if="dialogs.action" class="py-0">
        <v-spacer></v-spacer>
        <v-btn          
          class="mx-3 mt-0 mb-4 bg-warning"
          elevation="6"
          @click.stop="dialogAction"
        >{{dialogs.actionTitle}}</v-btn>        
      </v-card-actions>
      <v-card-actions v-if="dialogs.close && dialogs.closeTitle" class="py-0">
        <v-spacer></v-spacer>       
        <v-btn
          class="mx-3 mt-0 mb-4 bg-primary"
          elevation="6"
          @click.stop="dialogOff"
        >{{dialogs.closeTitle}}</v-btn>        
      </v-card-actions>      
    </v-card>
  </v-dialog>     
</template>

<script>
/*
+ Your time brings
+ Connecting comments
+ ex-qu backuping
+ free statement only to service
+ action error processing:
      completeQueue
      + pointComplete	- alert Already complete, cleanup
      + complete		  - alert NOt Your queue
      + point		      - alert Not in queue, cleanup
        default       - nothing

      skipQueue
      + pointSkip	    - alert Impossible to
      + point		      - alert Not in queue, cleanup
        default       - nothing

      dropQueue
      + pointComplete	- alert Already complete, cleanup
      + point		      - alert Not in queue, cleanup
        default       - nothing
+ new dialog themes info
+ confirm drop
+ drawer qu selector
+ allow skip queue
+ www mandatory
+ restart empty / start idx
+ websockets to point
+ ws getToken API method
+ channels / queues ; server /client tokens
+ message decoding
+ page scrolling
+ reboot on api errors
+ notifications events
+ unexpected API error on query complete and switch to other
+ free -> service Invalid statement send??
+ notification link
+ fb-tokens auto-update, return token id
+ skip queue during free - push your next :(
+ close old notification of the same queue
+ overlay on API busy
+ complete by token getTo()
+ dialog titles
+ manual and sheduled connect queue blocking and hide connect button too
+ Point IDX autoincr.
+ timestampShift setting
+ connectCurrentQueue() and readCurrentQueue() other errors processing:
+ reboot on close dialog button
+ processFirebaseJson to show important message

Why pushes to closed points?
info: you will pushed by N points before
TESTING impossibleIn queueNoExists
timebreaks & workday
send my queue


*/
import { apiGet, wsSubscribe, wsUnsubscribe } from "./Api2.js";
import { DEF, API_ERROR, QU_STATEMENT } from "./Defaults.js";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
let Sha = require('sha.js');

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      def: DEF,
      statement: QU_STATEMENT,
      qu: structuredClone(DEF.qu),
      config: structuredClone(DEF.config),
      exQu: structuredClone(DEF.qu),
      dialogs: structuredClone(DEF.dialogs),
      appThemes: [
        'apiError',
        'wipeConfirm',
        'push'
      ],
      langs: DEF.langs,
      drawer: false,
      fbToken: null,
      fbTokenSha: null,
      fbTokenId: null,
      fbApp: null,
      fbMessaging: null,
      fbSwitcher: false,    // FB Listener active
      showTitle: 0,
      welcome: 0,           // 2 - allow welcome page
      wsLocker: false,      // Lock to process websockets ping. When i something to do with queue
      firebaseLocker: false, // lock buttons while wirebase inits
      busy: false,
      busyFlag: false,
      timestamp: 0,
      timestampShift: 0
    }
  },
  mounted() {
    document.getElementById('app').style.marginTop = 0;
    this.timestamp = Math.floor(new Date().getTime()/1000 + this.timestampShift);
    setInterval(()=>{
      this.timestamp = Math.floor(new Date().getTime()/1000 + this.timestampShift);
    }, DEF.reactiveTimerInterval);    
    this.dataReset().then(()=>{
      this.getRegistrationToken().then(()=>{
        this.checkQueueComplete();
        //this.pluralShow('sk');
      });
    });
  },
  computed: {
    afterYou() {  // How many after you or null
      return (this.qu?.statistic?.total_in > (this.qu?.statistic?.in + 1)) ? 
        this.qu?.statistic?.total_in - this.qu?.statistic?.in - 1 : 0;
    },
    averageService() {   // Average service of time. Date obj.
      if(this.qu?.statistic?.serviced_count > 1) {
        return new Date(1000 * this.qu?.statistic?.serviced_time/this.qu?.statistic?.serviced_count);
      } else {
        return null;
      }
    },
    queuesList() {
      let list = [];
      Object.keys(this.config.queues).forEach(key => {
        list.push({
          token: key,
          title: this.config.queues[key].title
          });
      });
      return list;
    },
    userdataUrl() {
      return this.dataUrl(this.qu.config?.userdata, this.qu.config?.userdata?.keytype ? this.qu.point?.token : this.qu.point?.numero);
    },
    userinfoUrl() {
      return this.dataUrl(this.qu.config?.userinfo, this.qu.config?.userinfo?.keytype ? this.qu.point?.token : this.qu.point?.numero);
    },
    userpayUrl() {
      return this.dataUrl(this.qu.config?.userpay, this.qu.config?.userpay?.keytype ? this.qu.point?.token : this.qu.point?.numero);
    },    
    dialogTitle() {
      return this.appThemes.includes(this.dialogs.theme) ? this.$t('message.main.alert') : this.exQu.title;
      //  (this.exQu.title + ((this.dialogs.theme == 'connect') ? ('. ' + this.$t('message.dialogs.cnctQueueHead')) : ''));
    },
    booktimeState() {
      if(this.qu.closed) {
        return false;
      }
      let state = false;
      if(this.qu.shedule) {
        if(this.qu.shedule.booktime) {
          this.qu.shedule.booktime.forEach(book => {
            if((book.from_stamp <= this.timestamp)
              && (book.to_stamp >= this.timestamp)) {
              state = true;
            }            
          });
        }
      }
      return state;
    }    
  },
  methods: {
    // ------------------ Firebase. Switch to queue for about message -----------------------
    log(data) {
      if(DEF.log) {
        console.log(data);
      }
    },
    landingUrl() {
      window.open(this.$t('message.main.landingUrl'),"_blank");
    },
    processFirebaseJson(jsonData)
    {
      try	{      
        let data = JSON.parse(jsonData);
        this.log('Firebase Json: '+JSON.stringify(data));
        if(data?.dialog) {
          this.dialog(data?.dialog);
        }
      } catch(err) {
        this.log('Bad Push Message Json');
      }
    },
    firebaseSwitcher() {      
      if(!this.fbSwitcher) {
        navigator.serviceWorker.addEventListener("message", messageEvent => {
          let payload = null;
          try	{
            payload = JSON.parse(messageEvent.data)
          }
          catch (err)	{
            this.log('Push Message without data');
          }
          if(payload) {
            this.log('Push Message: '+JSON.stringify(payload));
            if(payload?.message?.data?.qu) {
              if(payload?.message?.data?.qu == this.qu.token) {   // No need to change Queue
                if(payload?.message?.data?.json) {
                  this.processFirebaseJson(payload?.message?.data?.json);
                }
              } else {
                this.log('Switch to '+payload?.message?.data?.qu);
                wsUnsubscribe();
                this.setQueue(payload?.message?.data?.qu, true).finally(()=>{
                  if(payload?.message?.data?.json) {
                    this.processFirebaseJson(payload?.message?.data?.json);
                  }
                });
              }
            }
          }
        });
        window.setTimeout(()=>{
          this.fbSwitcher = true;
          if(this.fbMessaging?.swRegistration?.active) {    // Informs ServiceWorkers about ready-to-receive message & data
            this.log('Ready to receive messages from FirebaseSW');
            this.fbMessaging.swRegistration.active.postMessage(JSON.stringify({qu:this.qu.token,active:true}));  
            if(this.$mainWorker) {
              this.log('Main ServiceWorker found');
              this.$mainWorker.postMessage(JSON.stringify({qu:this.qu.token,active:true})); 
            }
          }
        }, 1000);
      }
    },
    async getRegistrationToken() {
      return new Promise((resolve) => {
        if(this.config.push) {
          this.log('===== Push request allowed =====');
          if(!DEF.debug) {
          this.firebaseLocker = true;
          this.fbApp = initializeApp(DEF.firebaseConfig);
          this.fbMessaging = getMessaging(this.fbApp);
            Notification.requestPermission().then((permission) => {
              if(permission === 'granted') {
                getToken(this.fbMessaging, { 
                  vapidKey: DEF.firebaseKey
                }).then((currentToken) => {
                  if(currentToken) {
                    this.fbToken = currentToken;
                    let fbsha = Sha('sha256').update(currentToken).digest('hex');
                    if(this.fbTokenSha != fbsha) {   // New firebase token
                      this.fbTokenSha = fbsha;
                      this.fbTokenId = null;  // first time
                      this.log('===== New Firebase Registration token:\n'+fbsha);
                    } else {
                      this.log('===== Existing Firebase Registration token:\n'+fbsha);
                    }
                    this.log('===== Firebase Registration token available:\n'+currentToken);
                    this.firebaseSwitcher();
                    if(this.qu.point?.token) {
                      this.actionCurrentQueue('firebasePing').then((result)=>{
                        if(!result?.error && result?.result?.firebase) {
                          this.fbTokenId = result?.result?.firebase;
                          this.log('API firebasePing ok: '+this.fbTokenId);
                        } else {
                          this.log('API firebasePing has errors');                           // Don't process API errors
                        }
                      }).catch(()=>{
                        //this.wsLocker = false;
                        this.apiErrorDialog();
                      }).finally(()=>{
                        this.storeFirebase();
                        resolve();
                      });
                    } else {
                      this.storeFirebase();
                      resolve();
                    }
                  } else {
                    this.log('===== Firebase Registration token non available');
                    resolve();
                  }
                  this.firebaseLocker = false;
                }).catch(() => {
                  this.log('===== Firebase Registration error');
                  this.firebaseLocker = false;
                  resolve();
                });
              } else {
                this.log('===== Notification permisson declined');
                this.firebaseLocker = false;
                resolve();               
              }
            }).catch(() => {
              this.log('===== Notification permisson error');
              this.firebaseLocker = false;
              resolve();
            });
          } else {
            this.fbToken = DEF.firebaseToken;
            this.fbTokenSha = Sha('sha256').update(this.fbToken).digest('hex');
            this.log('===== Default Firebase Registration token:\n'+this.fbTokenSha);
            if(this.qu.point?.token) {
              this.actionCurrentQueue('firebasePing').then((result)=>{
                this.log('Ping result', result);
                if(!result?.error && result?.result?.firebase) {
                  this.fbTokenId = result?.result?.firebase;
                } else {
                  this.log('API firebasePing has errors');                  // Don't process API errors
                }             
              }).catch(()=>{
                //this.wsLocker = false;
                this.apiErrorDialog();
              }).finally(() => {
                this.storeFirebase();
                resolve();
              });
            } else {
              this.storeFirebase();
              resolve();
            }
          }
        } else {
          this.fbToken = DEF.firebaseToken;
          this.log('===== Push request yet deny =====');
          this.dialog({
            title: this.$t('message.main.alert'),
            theme: this.$t('message.dialogs.pushInfo'),
            actionTitle: this.$t('message.dialogs.push'),
            action: this.push      
          });
          resolve();
        }
      });
    },
    checkFirebase() {
      if(this.qu.point?.firebase) {
        this.fbTokenId = this.qu.point.firebase;
        this.storeFirebase();
      }
    },
    storeFirebase() {
      if(this.fbTokenId && this.fbTokenSha) {
        this.storageSet('firebaseId', this.fbTokenId);
        this.storageSet('firebaseSha', this.fbTokenSha);
      }
    },
    // ------------------ Buttons observers -----------------------
    langSelect(locale) {
      this.drawer = false;
      this.storageSet('locale',locale);
      this.reboot();
    },
    queueItemSelect(value) {
      wsUnsubscribe();
      this.setQueue(value, true);
      this.drawer = false;
    },
    wipe() {
      //wsUnsubscribe();
      this.storageClear();
      //this.dataReset();
      this.reboot();
    },
    connect() {
      wsUnsubscribe();
      this.connectCurrentQueue(true).then((err) => {
        if(!err) {
          if(this.config.queues[this.qu.token]) {
            this.checkFirebase();
            if(this.config.queues[this.qu.token]) {
              this.config.queues[this.qu.token].token = this.qu.point.token;
            }
            this.storageSet('queues', JSON.stringify(this.config.queues));
            this.dataReset().then(()=>{   // Dialog Connect
              let connectTxt = '<p><strong>' + this.$t('message.dialogs.cnctQueueHead') + '</strong></p><p class="intro">'
                +this.$t('message.dialogs.cnctQueue')+'</p><p class="intro">'+this.$t('message.dialogs.cnctQueueReconnect')+'</p>';
              if(this.qu.config?.push) {
                connectTxt += '<p class="intro">'+this.$t('message.dialogs.cnctQueuePush')+'</p>';
              }
              if(this.qu.config?.allow_skip) {
                connectTxt += '<p class="intro">'+this.$t('message.dialogs.cnctQueueSkip')+'</p>';
              }
              connectTxt += '<p class="intro">'+this.$t('message.dialogs.cnctQueueDrop')+'</p>';
              if(this.qu.config?.userdataAllowed) {
                connectTxt += '<p class="intro">'+this.$t('message.dialogs.userdataInfo')+'</p>';
              }
              if(this.qu.config?.userpayAllowed) {
                connectTxt += '<p class="intro">'+this.$t('message.dialogs.cnctQueuePay') + ' ' 
                  + this.$t('message.main.userPayInfo')+'</p>';
              }
              this.dialog({    
                title: this.exQu.title,
                theme: connectTxt          
              });
            });        
          }
        } else {
          switch(err) {
            case API_ERROR.pointCreate:
              this.dataReset().then(()=>{
                this.fastDialog('impossibleIn');
              });
              break;
            case API_ERROR.queue:
              this.cleanup('queueNoExists');
              //this.fastDialog('queueNoExists');
              break;
            case API_ERROR.noQueue:
              //this.fastDialog('queueNoExists');
              break;             
            default:
              this.unknownErrorDialog();              
              break;              
          }
        }
      }).catch(()=>{
        this.apiErrorDialog(); 
      });
    },    
    skip() {
      if(this.qu?.token &&
        this.config.queues[this.qu.token]?.token &&
        this.afterYou) {                // Skipping allowed
        this.wsLocker = true;
        this.actionCurrentQueue('skipQueue', true).then((result) => {
          wsUnsubscribe();
          switch(result?.error) {
            case API_ERROR.none:               
              this.dataReset().then(()=>{
                this.fastDialog('skpQueue');
              });
              break;             
            case API_ERROR.pointSkip:
              this.dataReset().then(()=>{
                this.fastDialog('impossibleToSkip');
              });
              break;
            case API_ERROR.point:
              this.cleanup('notInQueue');
              //this.fastDialog('notInQueue');
              break; 
            case API_ERROR.noQueue:
              //this.fastDialog('queueNoExists');
              break;  
            default:
              this.unknownErrorDialog();
              break;                             
          }
        }).catch(()=>{
          this.apiErrorDialog();          
        }).finally(()=>{
          this.wsLocker = false;
        });
      }
    },
    reboot() {
      wsUnsubscribe();
      //window.location.replace('/');
      this.log('== REBOOT in 10 sec ==');
      window.setTimeout(()=>{
        window.location.replace('/');
      },DEF.debug ? DEF.debugRebootTimeout : 1);
    },
    drop() {
      if(this.qu?.token &&
        this.config.queues[this.qu.token]?.token) {            // Dropping queue exists
        this.wsLocker = true;
        this.actionCurrentQueue('dropQueue', true).then((result) => {
          wsUnsubscribe();
          switch(result?.error) {   
            case API_ERROR.none:    
              this.killCurrentQueue();
              this.dialog({
                title: this.exQu.title,
                theme: this.$t('message.dialogs.drpQueue'),
                close: this.reboot
              });              
              break;                                
            case API_ERROR.pointComplete:
              this.cleanup('alreadyComplete');
              //this.fastDialog('alreadyComplete');
              break;
            case API_ERROR.point:
              this.cleanup('notInQueue');
              //this.fastDialog('notInQueue');
              break;
            case API_ERROR.complete:
              this.fastDialog('notYourQueue');
              break;                  
            case API_ERROR.noQueue:
              //this.fastDialog('queueNoExists');
              break; 
            default:
              this.unknownErrorDialog();
              break;                                                                     
          }
        }).catch(()=>{
          this.apiErrorDialog();          
        }).finally(()=>{
          this.wsLocker = false;
        });
      }
    },
    complete() {
      if(this.qu?.token &&
        this.config.queues[this.qu.token]?.token) {            // Complete queue exists     
        this.wsLocker = true; 
        this.actionCurrentQueue('completeQueue', true).then((result) => {
          switch(result?.error) {
            case API_ERROR.none:
              wsUnsubscribe(); 
              this.cleanup('okQueue');
              break;            
            case API_ERROR.pointComplete:
              wsUnsubscribe(); 
              this.cleanup('alreadyComplete');
              break;
            case API_ERROR.complete:
              this.fastDialog('notYourQueue');
              this.storageUnset('complete');
              break;                
            case API_ERROR.point:
              wsUnsubscribe(); 
              this.cleanup('notInQueue');
              break;
            case API_ERROR.noQueue:
              break;       
            default:
              this.unknownErrorDialog();
              break;              
          }
        }).catch(()=>{
          this.apiErrorDialog();          
        }).finally(()=>{
          this.wsLocker = false;
        });
      }
    },
    push() {
      this.storageSet('push',1);
      this.config.push = true;
      this.getRegistrationToken();
    },
    // ------------------ Process -----------------------
    startOverlay() {
      this.busyFlag = true;
      window.setTimeout(()=>{
        if(this.busyFlag) {
          this.busy = true;
        }
      }, DEF.overlayTimeout);
    },
    stopOverlay() {
      this.busyFlag = false;
      this.busy = false;
    },
    cleanup(theme = null) {                                                // Already droped by server
      wsUnsubscribe();
      if(this.qu?.token &&
        this.config.queues[this.qu.token]) {                   // Complete queue exists      
        this.killCurrentQueue(theme ? false : true);
        if(theme) {
          this.dialog({
            title: this.exQu.title,
            theme: this.$t('message.dialogs.'+theme),
            close: this.reboot
          });   
        }
      }
    },    
    dataUrl(data, idx) {            // Return url with key=idx argument
      if(data?.url && data?.key) {
        return data.url + 
          (data.url.includes('?') ? '&' : '?') + 
          data.key + '=' + idx;
      }
      return null;
    },
    // ========================== Dialogs ======================
    OLDialog(theme) {
      this.dialogs.theme = theme;
      this.dialogs.show = true;
    }, 
    dropConfirmDialog() {
      this.dialog({
        title: this.exQu.title,
        theme: this.$t('message.dialogs.dropConfirm'),
        actionTitle: this.$t('message.main.dropQueue'),
        action: this.drop      
      });
    }, 
    wipeConfirmDialog() {
      this.dialog({
        title: this.$t('message.main.alert'),
        theme: this.$t('message.dialogs.wipeConfirm'),
        actionTitle: this.$t('message.dialogs.wipe'),
        action: this.wipe 
      });
    }, 
    unknownErrorDialog() {
      this.dialog({
        title: this.exQu.title,
        theme: this.$t('message.dialogs.unknownError'),
        actionTitle: this.$t('message.dialogs.reboot'),
        action: this.reboot      
      });
    },  
    apiErrorDialog() {
      this.dialog({
        title: this.$t('message.main.alert'),
        theme: this.$t('message.dialogs.apiError'),
        actionTitle: this.$t('message.dialogs.reboot'),
        action: this.reboot,
        close: null
      });
    },             
    fastDialog(theme) {
      this.dialog({
        title: this.exQu.title,
        theme: this.$t('message.dialogs.'+theme)
      });
    },
    dialogOff() {
      this.dialogs.show = false;
      if(this.dialogs.close) {
        this.dialogs.close();
      }
      this.dialogs = structuredClone(DEF.dialogs);
    },
    dialogAction() {
      this.dialogs.show = false;
      if(this.dialogs.action) {
        this.dialogs.action();
      }
      this.dialogs = structuredClone(DEF.dialogs);
    },
    dialog(data) {
      this.dialogs = structuredClone(DEF.dialogs);
      this.dialogs.closeTitle = this.$t('message.dialogs.close');
      this.dialogs.close = ()=>{};
      for(let key in data) {
        this.dialogs[key] = data[key];
      }
      this.dialogs.show = true;
    },    
    checkQueueComplete() {
      let token = window.localStorage.getItem('complete') ;   // Complete QR scanned
      if(token) {
        if(this.config.queues[token]?.token) {                // Complete queue exists
          this.startOverlay();
          wsUnsubscribe();
          this.setQueue(token, false).then(() => {
            this.complete();
          }).finally(()=>{
            this.stopOverlay();
          });
        }
      }
    },
    killCurrentQueue(reboot = false) {
      if(this.qu.token) {
        let current = this.qu.token;
        this.qu = structuredClone(DEF.qu);
        delete this.config.queues[current];
        this.storageSet('queues', JSON.stringify(this.config.queues));
        this.storageUnset('current');
        let complete = window.localStorage.getItem('complete');
        if(complete == current) {
          this.storageUnset('complete');
        }
        if(reboot) {
          this.reboot();
        }
      }
    },
    async dataReset() {
      this.backupQu();
      this.drawer = false;
      this.fbToken = null;
      this.qu = structuredClone(DEF.qu);
      this.config = structuredClone(DEF.config);
      this.configRead();
      this.$i18n.locale = this.config.locale;
      this.$i18n.fallbackLocale = this.config.locale;
      return this.processCurrentQueue(true);
    },
    backupQu() {
      if(this.qu.token) {
        this.log('=== Qu Fixed ===');
        this.exQu = structuredClone(this.qu);
      }
    },
    async setQueue(token, sub = true) {
      this.backupQu();
      if(typeof this.config.queues[token] == 'undefined') {
        this.config.queues[token] = structuredClone(DEF.queue);
      }
      this.qu.token = token;
      this.qu.point.token =this.config.queues[token].token;
      this.storageSet('current',token);
      return this.processCurrentQueue(sub);
    },
    async processCurrentQueue(sub = true) {
      return this.readCurrentQueue(sub).then((err)=>{
        if(!err) {
          if(!this.exQu?.token) {
            this.backupQu();
          }
          if(!this.qu.point?.id) {            // If no point yet
            this.qu.point = structuredClone(DEF.qu.point);
          } else if(sub) {                                  // Subscribe on WS
            this.wsSubscribe().catch((err)=>{
              this.log(err.message);
              this.apiErrorDialog();
            });
          }
          if(this.qu.title) {
            if(this.config.queues[this.qu.token]) {
              this.config.queues[this.qu.token].title = this.qu.title;
            }
            this.storageSet('queues', JSON.stringify(this.config.queues));
          }
          if(this.qu.point?.to) {
            this.cleanup('alreadyComplete');
          } else {
            this.checkFirebase();
          }
        } else {
          switch(err) {
            case API_ERROR.goodbye:
              this.cleanup('okQueue');
              break;              
            case API_ERROR.queue:
              this.cleanup('queueNoExists');
              //this.fastDialog('queueNoExists');
              break;     
            case API_ERROR.point:
              this.cleanup('notInQueue');
              //this.fastDialog('notInQueue');
              break;
            case API_ERROR.noQueue:
              //this.fastDialog('queueNoExists');
              break;
            default:
              this.unknownErrorDialog();              
              break;              
          }
        }
      }).catch(()=>{
        this.apiErrorDialog(); 
      });
    },
    // ------------------ Config -----------------------
    configRead() {
      let queueSaveFlag = false;
      // --------------------- locale
      this.config.locale = window.localStorage.getItem('locale') || DEF.config.locale;
      // --------------------- push   
      this.config.push = !!window.localStorage.getItem('push');
      // --------------------- firebase
      this.fbTokenId = window.localStorage.getItem('firebaseId') || null;
      this.fbTokenSha = window.localStorage.getItem('firebaseSha') || null;
      // --------------------- current queue (by token)
      this.qu.token = window.localStorage.getItem('current') || DEF.token;
      // --------------------- queues 
      let qus = window.localStorage.getItem('queues');
      if(qus) {
        this.config.queues = JSON.parse(qus);
      } else if(this.qu.token) {                            // No stored Queues. First time new config creation by token
        this.config.queues = {[this.qu.token]: structuredClone(DEF.queue)};
        queueSaveFlag = true;
      } else {
        this.config.queues = structuredClone(DEF.config.queues);
        queueSaveFlag = true;
      }
      // --------------------- queues points
      if(this.qu.token) {
        if(this.config.queues[this.qu.token]) {           // Known Queue. 
          if(this.config.queues[this.qu.token].token) {   // Point token in Queue exists. I'm already in this queue.
            this.log('CONFIG READ: I`m already in this queue');
            this.qu.point.token = this.config.queues[this.qu.token].token;
          } else {                                        // No yet point. Non new but non busy queue for me
            this.log('CONFIG READ: Non new but non busy queue for me');
          }
        } else {                                          // Unknown Queue for me
          this.log('CONFIG READ: New queue for me');
          this.config.queues[this.qu.token] = structuredClone(DEF.queue);
          queueSaveFlag = true;
        }
      } else if(Object.keys(this.config.queues).length) {   // Queues exists
        this.qu.token = Object.keys(this.config.queues)[0];     // First of default config queues
        this.qu.point.token = this.config.queues[this.qu.token]?.token;
      }
      // --------------------- save config
      this.storageSet('current',this.qu.token);    
      if(queueSaveFlag) {
        this.log('== INIT SET QUEUE ==');
        this.storageSet('queues', JSON.stringify(this.config.queues));
      }
    },    
    // =================== API methods ============================
    async wsSubscribe() {
      this.wsLocker = false;
      return wsSubscribe(this.qu.token, this.onMessage);      
    },
    onMessage(msg) {
      if(this.qu.token == msg) {
        if(!this.wsLocker) {
          this.setQueue(this.qu.token, false);
        }
      } else if(msg == 'error') {
        this.apiErrorDialog();
      }
    },    
    storageSet(key, value) {
      if(window.localStorage.getItem(key) != value) {
        window.localStorage.setItem(key, value);
      }
    },
    storageUnset(key) {
      window.localStorage.removeItem(key);
    },    
    storageClear() {
      window.localStorage.clear();
    },    
    /** Store qu.point object.
     * Resolves null or error id.
     * Rejectes nothing. */
    async connectCurrentQueue(overlay = false) {
      return new Promise((resolve, reject) => {
        if(this.qu.token) {
          if(overlay) {
            this.startOverlay();
          }
          apiGet({
            ver: DEF.apiVersion,
            method: "inQueue",
            data: { 
              lang: this.config.locale,
              token: this.qu.token,
              fb_token: this.fbToken,
              fb_id: this.fbTokenId            
            }
          }).then((result) => {
            if(result.error) {
              resolve(result.error);
            } else {            
              if(result.result) {
                this.qu.point = result.result;
                resolve(null);
              } else {
                reject();
              }
            }
          }).catch(()=>{
            reject();
          }).finally(()=>{
            this.stopOverlay();
          });
        } else {
          resolve(API_ERROR.noQueue);
        }
      });     
    },        
    /**  Uses for: firebasePing skipQueue dropQueue completeQueue.
     * Resolves result object. Nothing to do 
     * Rejectes nothing. Nothing to do */
    async actionCurrentQueue(method, overlay = false) { 
      return new Promise((resolve, reject) => {
        if(this.qu.token) {
          if(overlay) {
            this.startOverlay();
          }          
          apiGet({
            ver: DEF.apiVersion,
            method: method,
            data: { 
              lang: this.config.locale,
              point: this.qu.point?.token,
              fb_token: this.fbToken,
              fb_id: this.fbTokenId
            }
          }).then((result) => {
            resolve(result);
          }).catch(()=>{
            //this.log(err.message);
            reject();
          }).finally(()=>{
            this.stopOverlay();
          });
        } else {
          resolve({error: API_ERROR.noQueue});
        }
      });
    },
    /** Store qu object. Fixes timestampShift
     * Resolves null or error id.
     * Rejectes nothing */    
    async readCurrentQueue(overlay = false) {
      return new Promise((resolve, reject) => {
        if(this.qu.token) {
          if(overlay) {
            this.startOverlay();
          }
          apiGet({
            ver: DEF.apiVersion,
            method: "getQueue",
            data: { 
              lang: this.config.locale,
              token: this.qu.token,
              point: this.qu.point?.token,
              fb_token: this.fbToken,
              fb_id: this.fbTokenId              
            }
          }).then((result) => {
            if(result.error) {
              resolve(result.error);
            } else {
              if(result.result?.sync) {     // Timer syncronisation
                this.timestampShift = result.result?.sync - Math.floor(new Date().getTime()/1000);
              }   
              if(result.result) {     
                this.qu = result.result;
                resolve(null);
              } else {
                reject();
              }              
            }
          }).catch(()=>{
            reject();
          }).finally(()=>{
            this.stopOverlay();
          });
        } else {
          resolve(API_ERROR.noQueue);
        }
      });
    },
    pluralShow(locale) {
      let plural = new Intl.PluralRules(locale);
      this.log('Plurals in '+locale);
      for(let idx=0; idx<=30; idx++) {
        this.log(idx+': '+plural.select(idx));
      }
    }
  },
  watch: {
    qu: {
      deep: true,
      handler(value) {
        if(value.token) {     // Show queue name as page title
          document.querySelector('title').innerHTML = value?.title + (value?.point?.numero ? (' :: '+value.point.numero) : '');
          this.exQu = structuredClone(value);
        } else {
          document.querySelector('title').innerHTML = this.$t('message.main.eQueue');
        }
        if(value.id) {
          this.welcome = 0;
        } else if(!this.welcome) {
          this.welcome = 1;
          window.setTimeout(()=>{
            this.welcome = 2;
          },DEF.welcomeTimeout);
        }
      }
    }
  }
}
</script>

<style>
.background {
  background-color: 'ffffe8';
  height: 100vh;
  width: 100vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
div.qu-stat {
  padding-bottom: 0;
  padding-top: 2px;
}
p.qu-memo {
  font-size: 0.9em;
  line-height: 120%;
  margin-bottom: 5px;
  margin-top: 5px;
}
#qu-title {
  font-weight: bold;
  font-size: 1.1em;
}
.v-overlay--active {
  text-align: center !important;
}
p.intro {
  margin-top: 8px;
}
</style>
