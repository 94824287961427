import { createApp } from 'vue';
import { DEF } from "./Defaults.js";
import App from './App.vue';
import i18n from './i18n';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

let token = null;
let reload = false;
let ok = false;
let muUrl = new URL(document.location);
if(DEF.debug) {
  window.httpsMandatory = false;
}
if(!(DEF.firebaseHost || DEF.debug || muUrl.origin.includes('www.'))) {   // www prefix mandatory
  window.location.replace(muUrl.protocol+'//www.'+muUrl.host);
} else if(DEF.firebaseHost && muUrl.origin.includes('.web.app')) {
  window.location.replace(DEF.firebaseUrl);
}
muUrl.pathname.split('/').forEach(elem => {
  if(token == 1) {
    token = elem;
  } else if(!token && (elem == 'id')) {
    token = 1;
  } else if(elem == 'ok') {
    ok = true;
  }
  if(elem.length) {
    reload = true;
  }
});
if(token && (token.length == DEF.tokenLength)) {
  window.quStorageFlag = true;
  window.localStorage.setItem('current',token);
  if(ok) {
    window.localStorage.setItem('complete',token);
  }
  window.quStorageFlag = false;
}
if(reload) {
  window.location.replace('/');
} else {

  window.addEventListener("storage", () => {
    window.location.replace('/');
  });

  const quTheme = {
    dark: false,
    colors: {
      background: 'ffffe0',
      background_y: 'ffff80',
      asurface: 'ffffe8',
      primary: '#404070',
      success: '#00e070',
      warning: '#d00000',
    },
  };
  const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: 'quTheme',
      themes: {
        quTheme,
      },
    },  
  });

  const app = createApp(App).use(vuetify).use(i18n);
  app.config.globalProperties.$mainWorker = null;
  window.addEventListener('DOMContentLoaded', () => {
    if(!DEF.debug && ('serviceWorker' in navigator)) {
      navigator.serviceWorker.register("/js/sw.js?v=2").then(workerRegistration => {
        app.config.globalProperties.$mainWorker = workerRegistration.active;
        if(DEF.log) {
          console.log("Service Worker registered: ", workerRegistration);
        }
      }).catch(error => {
        if(DEF.log) {
          console.error("Error registering the Service Worker: ", error);
        }
      });
    }
  });
  app.mount('#app');
}


/*
Старт приложения.
Проверяем наличие аргументов ok, id и token.
Если есть id, то localStorage.setItem('current',token);
Если есть еще и ок, то localStorage.setItem('complete',token);
И перезагружаемся без аргументов

mounted():
  Запускаем реактивный таймер
  dataReset():
    backupQu()  
      сохраняем qu в xQu, если есть qu.token (возможно он уже новый)
    Прячем меню
    Обнуляем fbToken, а qu и config заполняем DEF.qu и DEF.config
    configRead()
      Устанавливаем config.locale. Если его нет в localStorage, берем из DEF
      Устанавливаем config.push, fbTokenId, fbTokenSha, если они найдены в localStorage
      Устанавливаем qu.token. Ищем сначала window.quToken, затем "current" в localStorage, иначе DEF (пустая строка)
      Если в localStorage есть коллекция очередей 'queues', сохраняем ее в config.queues, иначе:
        *Если qu.token уже есть, создаем из DEF.queue очередь с qu.token и вносим ее в config.queues, иначе:
        *вносим в config.queues DEF.config.queues (пустой объект)
      Если qu.token уже есть, проверяем, есть ли такая очередь в config.queues.
        Если есть и токен клиента не пуст, вносим его в qu.point.token, т.е. мы уже стоим в этой учереди
        Если токен клиента пуст, значит мы еще в эту очередь не вошли, а только собрались.
        *Если очереди с таким qu.token в config.queues нет, создаем ее из DEF.queue
      Если qu.token все еще не задан, а коллекция config.queues не пуста, объявляем первую очередь из коллекции текущей, т.е.
        устанавливаем qu.token и qu.point.token
      Если qu.token изменился при старте, сохраняем его в localStorage
      Если выполнено нечто со (*), сохраняем config.queues в localStorage
    Активируем локаль из config.locale
    processCurrentQueue(true) с подпиской на канал
      readCurrentQueue(true) c оверлеем
        Если qu.token пуст, resolve(API_ERROR.noQueue), иначе:
          Включаем overley, если нужно
          Вызываем API getQueue с параметрами
              lang: config.locale,
              token: qu.token,
              point: qu.point.token,
              fb_token: fbToken,
              fb_id: fbTokenId
            Если ошибка, resolve(result.error)
            Если нет, синхронизируем таймер и сохраняем полученную очередь в qu
          Выключаем overlay
      Если очередь успешно получена:
        Если exQu пуст, бекапируем туда полученную очередь.
        Если в полученной очереди нет токена клиента (т.е. мы в ней еще не стоим),  копируем в qu.point DEF.qu.point
        Иначе, подписываемся на канал через qu.token wsSubscribe()
        Сохраняем имя очереди в ее записи в коллекции config.queues и сохраняем config.queues в localStorage
      Если очередь содежит qu.point.to, т.е. мы ее уже выстояли, то
        cleanup()
          Если задан qu.token и он есть в config.queues
          killCurrentQueue()
            Если задан qu.token, удаляем его запись в config.queues, 'current' в localStorage, сохраняем в localStorage config.queues
              Если 'complete' в localStorage соответствует qu.token,  удаляем и его
              dataReset() Сомнительно. Лучше бы перезагрузиться.

      checkFirebase()
        Если задан qu.point.firebase, сохраняем его в fbTokenId
        и storeFirebase()
          если заданы и fbTokenId и fbTokenSha, сохраняем оба в localStorage
  getRegistrationToken()
    Если не задан config.push
      копируем в fbToken DEF.firebaseToken и вызываем диалог насчет push
    Иначе инициируем процесс получения токена firebase. Когда токен получен, сохраняем его в fbToken, вычисляем SHA256
      Если SHA не совпадает с fbTokenSha, обнуляем fbTokenId, сохраняем SHA в fbTokenSha
      firebaseSwitcher()
        Назначаем обработчик "message" для serviceWorker:
          если сообщение payload.message содержит data.qu, отзываем подписку и переключаемся на такущую очередь 
            setQueue()
      Если задан qu.point.token, делаем API запрос firebasePing, получаем id в result.firebase, сохраняем его в fbTokenId
      storeFirebase()
        если заданы и fbTokenId и fbTokenSha, сохраняем оба в localStorage
    checkQueueComplete()
      Проверяем токен 'complete' в localStorage. Ищем его среди ключей config.queues с непустым клиентским токеном.
      Если найден, отменяем подписку и переключаемся на эту очередь без подписки:
      setQueue()
        Бэкапируем текущую очередь
        Если искомой очереди нет в config.queues, создаем ее из DEF.queue
        Устанавливаем новые qu.token и qu.point.token
        Сохраняем 'current' в localStorage
        processCurrentQueue() с учетом подписки
          ... см. выше, чего он делает
      Затем закрываем ее:
      complete()
        Проверяем наличие токенов очереди и клиента
          Если ок, вызываем API completeQueue
            Если нет ошибок, закрываем подписку и
            killCurrentQueue()

*/